var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }]
  }, [_c('p', [_vm._v("This allows bidders to provide bundle deals.")]), _c('div', {
    staticClass: "text-right mb-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleBundleEdit();
      }
    }
  }, [_vm._v(" + Add New Bundle ")])], 1), _vm._l(_vm.tableDataBundles, function (bundle, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-3"
    }, [_c('el-card', {
      key: index,
      staticClass: "box-card"
    }, [_c('div', {
      staticClass: "clearfix",
      attrs: {
        "slot": "header"
      },
      slot: "header"
    }, [_c('div', {
      staticClass: "mt-2",
      staticStyle: {
        "float": "left"
      }
    }, [_c('strong', [_vm._v("Bundle ID: " + _vm._s(bundle.uuid))])]), _c('div', {
      staticStyle: {
        "float": "right"
      }
    }, [_c('el-button', {
      attrs: {
        "size": "small"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.handleBundleEdit(bundle);
        }
      }
    }, [_c('mdicon', {
      attrs: {
        "name": "pencil",
        "size": 20
      }
    })], 1), _c('el-button', {
      attrs: {
        "size": "small"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.confirmDelete(bundle);
        }
      }
    }, [_c('mdicon', {
      staticClass: "text-danger",
      attrs: {
        "name": "trash-can",
        "size": 20
      }
    })], 1)], 1)]), _c('el-table', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "data": bundle.products
      }
    }, [_c('el-table-column', {
      attrs: {
        "prop": "name",
        "label": _vm.CombinationUtil.headermap_bid.trade_product_name
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "unit_quantity",
        "label": _vm.CombinationUtil.headermap_product.unit_quantity,
        "align": "right"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "bid_price_per_pack",
        "label": "Bid Price Per Pack",
        "align": "right"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "bundle_price_per_pack",
        "label": "Bundle Price Per Pack",
        "align": "right"
      }
    })], 1)], 1)], 1);
  }), _c('div', {
    staticClass: "rft-edit-draft-product-modal"
  }, [_c('RftEditActiveBundleModal', {
    ref: "RftEditActiveBundleModal"
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }